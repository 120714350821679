import React, { HTMLAttributes, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateCarPlate } from "../../../../apis/order";
import { getUserData, verifyOtpThenCreateUser } from "../../../../apis/user";
import { createWaitingList } from "../../../../apis/waitingList";
import { ReactComponent as LocationIcon } from "../../../../assets/icons/locationIcon.svg";
import {
  _8digitValidator,
  applyFormValidator,
  checkObjectIsNotEmpty,
} from "../../../../helper/checker";
import { ROUTE_PATHS } from "../../../../routes/paths/route-paths";
import useCarparkStore from "../../../../store/carpark";
import useGeneralStore from "../../../../store/general";
import useInfoStateStore from "../../../../store/infoState";
import BaseButton from "../../../atom/baseButton";
import BaseCarplateTextInput from "../../../atom/baseCarplateTextInput ";
import BaseDarkButton from "../../../atom/baseDarkButton";
import BaseModal from "../../../atom/baseModal";
import BaseOctopusNumberTextInput from "../../../atom/baseOctopusTextInput";
import BaseTextInput from "../../../atom/baseTextInput";
import BaseTitle from "../../../atom/baseTitle";
import BaseBreadCrumb from "../../../atom/breadcrumb";
import OtpRequestButton from "../../../atom/otpRequestButton";
import GalleryButton from "../../galleryButton";
import MonthlyOrderItemCard from "../../monthlyOrderItemCard";
import type { IBookingFormErrorMessages, IBookingFormValues } from "./type";

interface IApplyForm {
  goNext: () => void;
  goBack: () => void;
  goStep?: (step: number) => void;
  needFetchUserInfo?: boolean;
}

type TApplyFormProps = IApplyForm & HTMLAttributes<HTMLDivElement>;

const ApplyForm: React.FC<TApplyFormProps> = (props) => {
  const {
    goNext,
    goBack,
    goStep,
    needFetchUserInfo = false,
    ...otherProps
  } = props;
  const navigate = useNavigate();

  const [bookingFormValues, setBookingFormValues] =
    useState<IBookingFormValues>({
      name: "",
      mainCarPlate: "",
      email: "",
      phone: "",
      octopusNumber: "",
      octopusLastDigit: "",
    });

  const [otp, setOtp] = useState<string>("");
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [readyToRedirect, setReadyToRedirect] = useState("");
  const [errorMessages, setErrorMessages] = useState<IBookingFormErrorMessages>(
    {}
  );

  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);
  const selectedMonthlyItem = useInfoStateStore(
    (state) => state.selectedMonthlyItem
  );
  const formDataInStore = useInfoStateStore((state) => state.applyFormData);
  const setApplyFormData = useInfoStateStore((state) => state.setApplyFormData);

  const carparkInfoList = useCarparkStore((state) => state.carparkInfoList);

  const selectedCarparkInfo = carparkInfoList.find(
    (carpark) =>
      carpark.id.toString() === selectedMonthlyItem?.parking_id.toString()
  );

  const IS_FULL = selectedMonthlyItem?.available! <= 0;
  const disableApplyButton =
    bookingFormValues.name === "" ||
    bookingFormValues.mainCarPlate === "" ||
    bookingFormValues.email === "" ||
    bookingFormValues.phone === "" ||
    otp === "";

  const onCloseAlertModal = () => {
    setShowAlertModal(false);
  };

  const onchangeBookingFormValues = (
    key: keyof IBookingFormValues,
    value: string
  ) => {
    setBookingFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const fetchUserInfo = async () => {
    try {
      const loginState = JSON.parse(
        localStorage.getItem("wbloginState") || "{}"
      );
      if (loginState.phone === undefined) return;
      setGlobalLoadingState(true);
      const userData = await getUserData(loginState.phone);

      setBookingFormValues({
        ...bookingFormValues,
        userID: userData.id,
        name: userData.username,
        email: userData.email,
        phone: userData.phone,
      });
      // setUserInfo({
      //   name: userData.username,
      //   mainCarPlate: userData.user_lpn ?? "",
      //   email: userData.email,
      //   phone: userData.phone,
      // });
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  const checkAnyBookedCarplate = async () => {
    try {
      const validateResult = await validateCarPlate(
        selectedMonthlyItem?.parking_id.toString()!,
        bookingFormValues.mainCarPlate
      );
      if (validateResult) {
        if (validateResult.status === "repeat") {
          setShowAlertModal(true);
          return true;
        }
      }
      return false;
    } catch (error) {}
  };

  // call create waiting list api
  const joinQueue = async () => {
    try {
      setGlobalLoadingState(true);
      const createResult = await createWaitingList({
        parking_id: selectedMonthlyItem?.parking_id!,
        monthly_order_item_id: selectedMonthlyItem?.id!,
        u_name: bookingFormValues.name,
        u_email: bookingFormValues.email,
        u_tel: bookingFormValues.phone,
        u_lpn: bookingFormValues.mainCarPlate,
        octopus_number:
          bookingFormValues.octopusNumber && bookingFormValues.octopusLastDigit
            ? `${bookingFormValues.octopusNumber}(${bookingFormValues.octopusLastDigit})`
            : undefined,
      });
      if (createResult.wl_token) {
        setGlobalLoadingState(false);
        setReadyToRedirect(createResult.wl_token);
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  const onsubmitApplyForm = async () => {
    // other form validation
    try {
      const errors = applyFormValidator(bookingFormValues);

      setErrorMessages((prev) => ({ ...prev, ...errors }));

      // check otp is empty
      if (otp.length < 6) {
        setErrorMessages((prev) => ({
          ...prev,
          otp: "請輸入6位數字驗證碼",
        }));
        return;
      } else {
        setErrorMessages((prev) => ({ ...prev, otp: "" }));
      }

      if (checkObjectIsNotEmpty(errors)) {
        return;
      }

      // call api to check otp is valid
      const verifyOtpResponse = await verifyOtpThenCreateUser(
        bookingFormValues.phone,
        otp,
        bookingFormValues.name,
        bookingFormValues.email
      );
      const canByPassOtp =
        process.env.REACT_APP_ENV === "dev" ||
        process.env.REACT_APP_ENV === "LOCAL";

      if (verifyOtpResponse || canByPassOtp) {
        if (IS_FULL) {
          // call api to create waiting list
          await joinQueue();
        } else {
          setApplyFormData({
            ...bookingFormValues,
            userID: verifyOtpResponse?.id,
          });
          // call api to create subscription
          const checkHadSameCarplate = await checkAnyBookedCarplate();
          if (!checkHadSameCarplate) {
            if (selectedMonthlyItem?.stripe_extra_car_plate_price_id) {
              goNext();
            } else {
              goStep && goStep(7);
            }
          }
        }
      } else {
        setErrorMessages((prev) => ({
          ...prev,
          otp: "驗證碼錯誤",
        }));
      }
    } catch (error) {}
  };

  // re-fill email and phone if user has login
  useEffect(() => {
    if (needFetchUserInfo) {
      fetchUserInfo();
    }
  }, []);

  useEffect(() => {
    if (formDataInStore) {
      setBookingFormValues(formDataInStore);
    }
  }, [formDataInStore]);

  useEffect(() => {
    if (readyToRedirect) {
      navigate(`${ROUTE_PATHS.QUEUE_PAGE}?wlt=${readyToRedirect}`);
    }
  }, [readyToRedirect]);

  return (
    <div className="flex flex-1 flex-col gap-4" {...otherProps}>
      {
        <BaseBreadCrumb
          onClick={() => {
            if (IS_FULL) {
              goStep && goStep(2);
            } else {
              goBack && goBack();
            }
          }}
        >{`<上一頁`}</BaseBreadCrumb>
      }
      {selectedCarparkInfo && (
        <BaseTitle text={selectedCarparkInfo?.display_name || ""} />
      )}
      {selectedCarparkInfo && (
        <div className="flex flex-row justify-between items-center">
          <a
            className="link flex flex-row items-center text-xl gap-2"
            href={selectedCarparkInfo?.map}
            target="_blank"
          >
            <LocationIcon /> {selectedCarparkInfo?.location}
          </a>

          <GalleryButton />
        </div>
      )}

      <MonthlyOrderItemCard
        key={selectedMonthlyItem?.id}
        item={selectedMonthlyItem!}
      />

      {/* input form section */}
      <div className="flex flex-col gap-4 my-3 justify-center items-center">
        <BaseTextInput
          placeholder="車主名稱"
          required
          value={bookingFormValues?.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onchangeBookingFormValues("name", e.target.value);
          }}
          errorMessage={errorMessages?.name}
        />

        <BaseCarplateTextInput
          value={bookingFormValues?.mainCarPlate}
          onChangeCarplate={(value) => {
            onchangeBookingFormValues("mainCarPlate", value);
          }}
          errorMessage={errorMessages.mainCarPlate}
        />

        <BaseTextInput
          placeholder="電郵"
          required
          disabled={!!bookingFormValues.userID}
          value={bookingFormValues?.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onchangeBookingFormValues("email", e.target.value);
          }}
          errorMessage={errorMessages?.email}
        />

        <BaseTextInput
          placeholder="電話號碼"
          required
          disabled={!!bookingFormValues.userID}
          value={bookingFormValues?.phone}
          rightElement={<OtpRequestButton phone={bookingFormValues?.phone} />}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const isPhoneValid = _8digitValidator(e.target.value);
            if (!isPhoneValid) {
              return;
            }
            onchangeBookingFormValues("phone", e.target.value);
          }}
          errorMessage={errorMessages?.phone}
        />

        <BaseTextInput
          placeholder="驗證碼"
          required
          value={otp}
          maxLength={6}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const otpRegex = /^[0-9]{0,6}$/;

            if (!otpRegex.test(e.target.value)) {
              return;
            }
            setOtp(e.target.value);
          }}
          errorMessage={errorMessages?.otp}
        />

        <BaseOctopusNumberTextInput
          octopusNumber={bookingFormValues?.octopusNumber || ""}
          octopusLastDigit={bookingFormValues?.octopusLastDigit || ""}
          onchangeBookingFormValues={onchangeBookingFormValues}
          errorMessage={errorMessages?.octopusNumber}
        />

        <BaseButton
          onClick={onsubmitApplyForm}
          disabled={disableApplyButton}
          minWidth="w-full"
          disabledStyle="disabled:bg-mainYellow disabled:bg-opacity-60"
        >
          {IS_FULL ? "預約" : "下一步"}
        </BaseButton>
      </div>

      <BaseModal
        title="提示"
        titleStyle="text-white font-bold text-2xl"
        showCloseButton
        modalBaseStyle="bg-black"
        open={showAlertModal}
        onClose={onCloseAlertModal}
      >
        <div className="flex flex-col gap-4 text-white">
          <span>{`您的車牌 ${bookingFormValues.mainCarPlate} 已在${selectedCarparkInfo?.display_name}租用車位中，確認繼續？`}</span>
          <div className="flex flex-row justify-between items-center gap-4">
            <BaseDarkButton onClick={onCloseAlertModal} className="flex-1">
              取消
            </BaseDarkButton>
            <BaseButton
              onClick={() => {
                if (selectedMonthlyItem?.stripe_extra_car_plate_price_id) {
                  goNext();
                } else {
                  goStep && goStep(7);
                }
              }}
              className="flex-1"
            >
              繼續
            </BaseButton>
          </div>
        </div>
      </BaseModal>
    </div>
  );
};

export default ApplyForm;
