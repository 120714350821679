import React, { HTMLAttributes, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getMonthlyOrderItemById } from "../../apis/order";
import { getParkingList } from "../../apis/parking";
import AddExtraCarplatePage from "../../components/molecules/stepContainer/addExtraCarplatePage";
import ApplyForm from "../../components/molecules/stepContainer/applyForm";
import ConfirmForm from "../../components/molecules/stepContainer/confirmForm";
import ExtraCarplatePage from "../../components/molecules/stepContainer/extraCarplatePage";
import SelectRentDatePage from "../../components/molecules/stepContainer/selectRentDatePage";
import { customShowToast } from "../../helper/toast";
import { ROUTE_PATHS } from "../../routes/paths/route-paths";
import useCarparkStore from "../../store/carpark";
import useGeneralStore from "../../store/general";
import useInfoStateStore from "../../store/infoState";

interface ISpecialEventPage {}

type TSpecialEventPageProps = ISpecialEventPage &
  HTMLAttributes<HTMLDivElement>;

const SpecialEventPage: React.FC<TSpecialEventPageProps> = (props) => {
  const { ...otherProps } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const _event_id = searchParams.get("event_id");

  const [currentStep, setCurrentStep] = useState(3);
  const [isReady, setIsReady] = useState(false);

  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);

  const setCarparkInfoList = useCarparkStore(
    (state) => state.setCarParkInfoList
  );

  const { clearInfoState: resetInfoStore, ...infoStore } = useInfoStateStore(
    (state) => state
  );

  const goNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const goStep = (step: number) => {
    setCurrentStep(step);
  };

  // get parking list api
  const fetchAllParkingList = async () => {
    try {
      setGlobalLoadingState(true);
      const carparkResponse = await getParkingList();
      if (carparkResponse) {
        setCarparkInfoList(carparkResponse);
        setGlobalLoadingState(false);
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };
  // get event order item by id
  const fetchMonthlyOrderItemDetail = async (oid: string) => {
    try {
      const itemDetail = await getMonthlyOrderItemById(oid);
      if (itemDetail) {
        infoStore.setSelectCarpark(itemDetail.parking_id.toString());
        infoStore.setSelectItem(itemDetail);
        setIsReady(true);
      }
    } catch (error) {
      customShowToast("找不到相關項目");
    }
  };

  // fetch parking list on component mount for step one
  useEffect(() => {
    resetInfoStore();
    if (!_event_id) {
      return;
    } else {
      const isDevMode =
        process.env.REACT_APP_ENV === "dev" ||
        process.env.REACT_APP_ENV === "LOCAL";

      const isEventID = isDevMode
        ? _event_id === "17" || _event_id === "18" || _event_id === "19"
        : _event_id === "20" || _event_id === "21" || _event_id === "22";
      // blocking for not event id
      if (!isEventID) {
        customShowToast("不符合活動資格, 將會跳轉至首頁", "error", () =>
          navigate(ROUTE_PATHS.LANDING_PAGE)
        );
      } else {
        fetchAllParkingList();
        fetchMonthlyOrderItemDetail(_event_id);
      }
    }
  }, [_event_id]);

  if (!isReady) {
    return (
      <div className="flex flex-col justify-center skeleton h-80 w-full p-4 mt-10">
        <div className="skeleton h-4 w-32 m-1"></div>
        <div className="skeleton h-12 w-64 m-1"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-col gap-4 pt-5" {...otherProps}>
      {/* selecte start date page */}
      {currentStep === 3 && (
        <SelectRentDatePage goNext={goNext} showItemCard={true} />
      )}

      {/* apply form  */}
      {currentStep === 4 && (
        <ApplyForm goBack={goBack} goNext={goNext} goStep={goStep} />
      )}

      {/* extra carplate page */}
      {currentStep === 5 && (
        <ExtraCarplatePage goBack={goBack} goStep={goStep} />
      )}

      {/* add extra carplate form */}
      {currentStep === 6 && (
        <AddExtraCarplatePage goBack={goBack} goNext={goNext} />
      )}

      {/* confirm form */}
      {currentStep === 7 && <ConfirmForm goStep={goStep} />}
    </div>
  );
};

export default SpecialEventPage;
