import React, { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import type { IGetParkingItemsResponse } from "../../../apis/parking/type";
import { ReactComponent as InfoIcon } from "../../../assets/icons/infoIcon.svg";
import { ReactComponent as InfoIconRed } from "../../../assets/icons/infoIconRed.svg";
import { ReactComponent as ArrowRightBlackIcon } from "../../../assets/icons/parkSelectArrowRight.svg";
import useInfoStateStore from "../../../store/infoState";
import BaseChip from "../../atom/baseChip";
import { ITEM_TYPE_MAPPING } from "../subscriptionManageBlock";

interface IMonthlyOrderItemCard {
  item: IGetParkingItemsResponse;
  goStep?: (index: number) => void;
  pressable?: boolean;
  hideQuota?: boolean;
  showItemCard?: boolean;
}

type TMonthlyOrderItemCardProps = IMonthlyOrderItemCard &
  HTMLAttributes<HTMLDivElement>;

const MonthlyOrderItemCard: React.FC<TMonthlyOrderItemCardProps> = (props) => {
  const { item, goStep, pressable, hideQuota, showItemCard, ...otherProps } =
    props;
  const show = showItemCard ?? (item?.visible && !item.deleted);
  const isFull = item?.available <= 0;
  const itemTypeInfo = ITEM_TYPE_MAPPING[item?.item_type_id ?? 1];

  if (!show) {
    return null;
  }

  const selectItemHandler = () => {
    if (goStep) {
      useInfoStateStore.getState().setSelectItem(item);
      goStep(isFull ? 4 : 3);
    }
  };

  return (
    <div
      className={twMerge(
        "flex rounded-xl text-white p-4 hover:border-mainYellow cursor-pointer ",
        isFull
          ? "bg-mainRed border border-mainRed"
          : "bg-mainDark border border-mainDark",
        hideQuota ? "bg-[#363C48] border-[#363C48]" : "",
        goStep ? "" : "cursor-auto hover:border-transparent"
      )}
      onClick={selectItemHandler}
      {...otherProps}
    >
      <div className="flex flex-1 flex-row justify-between items-center gap-2">
        {/* left section */}
        <div className="flex flex-1 flex-col gap-2">
          <div className="flex flex-1 flex-row items-center gap-2">
            <BaseChip
              leftIcon={<InfoIcon />}
              text={
                item.interval_count > 1
                  ? `${item.interval_count}個月季票計劃`
                  : "月票"
              }
            />
            {isFull && (
              <div className="flex flex-row items-center bg-black rounded-xl px-3 py-1 gap-1">
                <InfoIconRed />
                <span className="text-xs text-alertRed font-bold">已滿</span>
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-row gap-2 items-center font-bold ">
            <div className="w-5 h-5"> {itemTypeInfo.icon}</div>
            {item.description
              ? `${item.display_name}(${item.description})`
              : item.display_name}
          </div>

          <div className="flex flex-1 flex-row gap-1 items-center text-xs font-bold ">
            <span>
              {item.interval_count > 1 ? item.interval_count : "每"}個月
            </span>
            <span className="text-mainYellow">
              {item.price.toLocaleString("zh-HK", {
                style: "currency",
                currency: "HKD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
            {item.interval_count > 1 && <span>*需要一次性付款</span>}
          </div>
        </div>

        {/* right section */}
        {!hideQuota && (
          <div className={twMerge("flex items-center gap-4")}>
            {isFull ? (
              <h6 className="text-sm font-bold">登記輪侯</h6>
            ) : (
              <>
                {item.available && (
                  <div className="flex flex-col flex-1 items-center">
                    <div className="text-xs">尚餘車位</div>
                    <div>
                      <span className="text-4xl font-bold text-mainYellow">
                        {item.available}
                      </span>
                      <span>個</span>
                    </div>
                  </div>
                )}
              </>
            )}
            {goStep && <ArrowRightBlackIcon />}
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthlyOrderItemCard;
